import React, {useMemo} from 'react';
import {motion} from 'framer-motion';
import {graphql, Link} from 'gatsby';
import Layout from 'components/Layout';
import {PageHeader} from 'components/Headings';
import {pageVariants, pageTransition} from 'components/Transitions';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BlogCard from 'components/BlogCard';
import CategoriesList from 'components/CategoriesList';

function Category({ data, ...rest}) {
  const { allCraftBlogArticlesBlogArticleEntry, craftBlogCategory, allCraftBlogCategory: {categories} } =
    data;
  const {
    seomatic,
    title,
    description,
    heroImage,
    id
  } = craftBlogCategory;

  const {edges} = allCraftBlogArticlesBlogArticleEntry;
  const hasUseableImage = useMemo(() => {
    if (!heroImage || heroImage.length === 0) {
      return false;
    }
    if (heroImage[0].width < 1280 || heroImage[0].height < 531) {
      return false;
    }
    return true;
  }, [heroImage]);
  const { path } = rest;
  return (
    <Layout hasContact seomatic={seomatic} preloadFooter>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}>
        <div className="blog font-display">
          <div className="relative flex flex-col">
            <PageHeader heading={title} subheading={ description} />
            <CategoriesList categories={ categories} path={ path}/>
            {hasUseableImage && (
              <div className="max-w-screen-xl m-auto w-full flex items-center justify-center overflow-hidden">
                <GatsbyImage className="hero-image" image={getImage(heroImage?.[0]?.localFile)} alt={title} />
              </div>
            )}
            <div className="md:row pl-3 md:pl-10 blog-author_container gap-8">
              <div className="article">
                <div className="article_card-outer">
                {edges.map(blog => (
                  <BlogCard key={blog.node.title} node={blog.node} />
                ))}
              </div>
            </div>
            </div>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String) {
    craftBlogCategory(id: {eq: $id}) {
      id
      slug
      description
      title
      seomatic {
        ...SeomaticFields
      }
    }
    allCraftBlogCategory {
      categories: nodes {
        id
        title
        slug
      }
    }
    allCraftBlogArticlesBlogArticleEntry(
      filter: {enabled: {eq: true}, status: {eq: "live"} , categories: {elemMatch: {id: {eq: $id}}}}
    ) {
      edges {
        node {
          url
          title
          intro
          heroImage {
            ... on Craft_assets_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 375
                    height: 250
                    quality: 80
                    transformOptions: {cropFocus: CENTER}
                  )
                }
              }
            }
          }
          categories {
            title
          }
        }
      }
    }
  }`;



export default Category;
